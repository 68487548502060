import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { injectGlobal } from 'react-emotion'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'react-emotion'
import { ThemeProvider } from 'emotion-theming'
import theme from '../utils/theme'

injectGlobal`
  html {
    box-sizing: border-box;
  }
  body {
    background-color: #212121;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  form.formkit-form {
    margin: auto;
  }
  button.formkit-submit {
    background-color: #D13B74 !important;
    border-radius: 0px !important;
  }
  input.formkit-input {
    border-radius: 0px !important;
  }
  .formkit-alert.formkit-alert-success {
    background-color: #598B82 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 0px !important;
  }
`
const Container = styled.div`
  background: blue;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'game of Service Worker mastery' },
            {
              name: 'keywords',
              content: 'PWA, Service Workers, Mastery Games, Coding',
            },
          ]}
        >
          <link rel="shortcut icon" href="/favicon.ico" />

          {/* Twitter Cards */}
          <meta
            property="og:image"
            content="https://serviceworkies.com/serviceworkies.png"
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="1920" />
          <meta property="og:title" content="Service Workies" />
          <meta
            property="og:description"
            content="the game of Service Worker mastery"
          />
          <meta property="og:url" content="https://serviceworkies.com" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@geddski" />
          <meta name="twitter:creator" content="@geddski" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <Container>{children}</Container>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
