// Light to dark
export const GRAY_1 = '#2c2e37'
export const GRAY_2 = '#1e1d24'
export const GRAY_3 = '#0f0f14'

export const PRIMARY = 'rgb(0, 156, 194)'
export const PRIMARY_ACTIVE = 'rgb(75, 197, 152)'

// theme
export default {
  colors: {
    gray_1: GRAY_1,
    gray_2: GRAY_2,
    gray_3: GRAY_3,
    primary: PRIMARY,
    primary_active: PRIMARY_ACTIVE,
  },
}
